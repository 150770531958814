.foot-notes {
  padding: 15px;
  color: #282828;
  .foot-notes-main {
    border: solid 1px #dbdbdb;
    border-radius: 10px;
    .header {
      padding: 4px 0px 4px 10px;
      font-size: 12px;
      font-weight: 500;
      border-bottom: solid 1px #dbdbdb;
      background-color: #f1f1f1;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
    }
    .foot-notes-row {
      cursor: pointer;
      display: flex;
      font-size: 13px;
      padding: 15px 10px;
      div {
        flex: 1;
      }
      i {
        font-size: 16px;
      }
    }
    .foot-notes-row:not(:last-child) {
      border-bottom: solid 1px #dbdbdb;
    }
  }
}

.footnote-select-wrapper {
  position: relative;
  padding: 11px;
  p {
    margin: 0 0 15px;
    font-size: 17px;
    font-weight: bold;
    color: var(--color-white);
    letter-spacing: 0.5px;
  }
  .footnote-select-inner {
    width: 340px;
    overflow: hidden;
    background-color: var(--color-white);
    box-sizing: border-box;
    border-radius: 4px;
    .header {
      font-size: 14px;
      color: #282828;
      padding: 7px 15px;
      background-color: #f1f1f1;
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;
    }
    .container {
      max-height: 600px;
      overflow-y: auto;
      .row {
        padding: 0 15px 15px 15px;
        .heading {
          color: #000000;
          font-size: 15px;
          font-weight: 500;
        }
        .content {
          font-size: 14px;
          color: #282828;
          margin: 5px 0;
          &:last-child {
            margin-bottom: 0;
          }
        }
        a {
          font-size: 13px;
          color: var(--color-green);
        }
      }
      .row > div:first-child {
        padding-top: 15px;
        border-top: 1px solid #dbdbdb;
      }
      .row:nth-child(1) > div:first-child {
        border-top:unset
      }

      &::-webkit-scrollbar {
        width: 8px;
        height: 8px;
        -webkit-appearance: none;
      }
      &::-webkit-scrollbar-thumb {
        background-color: #0e730f;
        border-radius: 100px;
      }
      &::-webkit-scrollbar-track {
        background-color: #d7edd7;
      }
    }
  }
}

.DetailBooking-Group .foot-notes {
  padding: 5px 0 10px 0;
}

.SingleBookingLayout .foot-notes,
.MultipleBookingLayout .foot-notes {
  padding-top: 0;
  margin-top: 15px;
}