.popover-overlay {
  // position: fixed;
  // top: 0;
  // left: 0;
  // bottom: 0;
  width: 420px;
  // background-color: rgba(0, 0, 0, .6);
  .popover-wrapper {
    position: fixed;
    background-color: #39b119;
    box-shadow: 0 0 10px rgba(0, 0, 0, .2);
    border-radius: 4px;
    z-index: 111;
    // top: 50%;
    left: 430px;
    
    // transform: translateY(-50%);
  }
  .popover-anchor {
    display: block;
    width: 10px;
    height: 10px;
    background-color: #39b119;
    transform: rotate(45deg);
    position: absolute;
    left: -5px;
    top: 50%;
    margin-top: -5px;
    &.right{
      right: -5px;
      left: unset;
    }
  }
}

@media (min-width: 1024px) and (max-width: 1366px) {
  .popover-overlay {
    zoom: 0.8;
    // .popover-anchor {
    //   &.right{
    //     right: -4px;
    //   }
    // }
  }
}